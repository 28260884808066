/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React, { useState } from 'react';
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql, Link } from "gatsby"
 
 import Header from "./header"
 import Footer from "./footer"
 import "./layout.css"
 import FavIcon from './fav-icon.js'
 import star from '../images/star.svg'

 class ProductItem extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        let item = this.props.item
        let title = this.props.item.title.replace(/Gesponserte Anzeige – /g, "").replace(/[#\?\*,%]/g, "").replace(/Sponsored Ad – /g, "").split(" -").splice(0, 3).join(", ")
        title = title
        let name = title.replace(/ /g, "-") 
        let articleNum = this.props.item.articleNum

        let imgObj = {
            img: "",
            width: 999999
        }
        
        if (item.img) {
            let arr = JSON.parse(item.img)
            for (let img in arr) {
                if (arr[img][0] < imgObj.width) {
                    imgObj.img = img
                    imgObj.width = arr[img][0]
                }
            }
        }

        let numRating = parseInt(item.numRating)
        let avgRating = parseFloat(item.avgRating.replace(",", "."))
        console.log("avg rating: " + avgRating)
    
        let totalAvgRating = avgRating
        let totalNumRating = numRating
        
        if (item.mattel_info) {
            if (item.mattel_info.avgRating && item.mattel_info.numRating) {
                totalNumRating = numRating + parseInt(item.mattel_info.numRating)
                totalAvgRating = (((avgRating * numRating) + (parseFloat(item.mattel_info.avgRating) * parseInt(item.mattel_info.numRating))) / totalNumRating)
            }
        }
        return (
            <>
                <div 
                style={{
                    position: 'relative'
                }}>
                    <div style={{zIndex: 9999, borderRadius: 50, position: "absolute", right: "40px", top: 10, width: 36, padding: 9, display: "flex", background: "#fff", borderRadius: 50}} className={"favs-icon " + (this.props.favs.indexOf(articleNum) > -1 ? "active" : "") } onClick={() => {
                                this.props.updateFavs(articleNum)
                            }}>
                            <FavIcon />
                        </div>
                            <Link className="product-item" style={{ display: "block", color: "var(--color-text)"}} title={title} to={"/produkt/" + title.replace("Streichholzbox", "").replace(/ /g, "-").toLowerCase() + "/"}>

                    <div style={{display: "flex", height: 150, position: "relative", justifyContent: "center"}}>
                        <img src={imgObj.img} title={title} alt={"Hot Wheels " + item.categories.join(", ")} style={{width: 150, height: 150, objectFit: "contain"}}/>
                        
                        
                    </div>
                    <div className='' style={{display: "flex",  fontSize: "0.8em", marginTop: 5, justifyContent: "space-between"}}>
                        <div style={{marginRight: 15}}>
                        {"Bewertungen: " + item.numRating} 

                        </div>
                        {totalAvgRating.toString() !== "NaN" &&
                            <div className='row'>
                                <img src={star} style={{height: "11px", margin: "auto", marginRight: 5, position: "relative", top: "-1px"}}/>
                                {totalAvgRating.toFixed(1) + " / 5"} 
                            </div>
                        }
                    </div>
                    <div className='row' style={{display: "flex", justifyContent: "space-between", marginBottom: 10, position: "relative", marginTop: 5}}>
                        <div style={{lineHeight: "16px", fontSize: "16px", overflow: "hidden", textOverflow: "ellipsis", fontWeight: 400, minHeight: 100, marginBottom: 10}}>{title.replace("Hot Wheels", "").replace(/^[\s]*,/, "").trim()}</div>
                        
                    </div>
                    
                    <div className='row' style={{justifyContent: "space-between"}}>
                        <div className=''>
                            <div style={{color: "var(--color-text-lighter)"}}>Preis:</div>
                            {parseInt(item.price) > 0  &&
                                <div className="price" style={{fontSize: "22px"}}>{item.price}</div> 
                            }
                        </div>
                        
                    
                    </div>
                    </Link>
                            
                </div>
            </>
        )
    }
    
 }

// <a className='amazon-button' href={item.url + "&tag=niklasoelze-21"} rel="nofollow" target={"_blank"} style={{padding: 5, display: "block"}}>Weiter zu Amazon</a>

 export default ProductItem
 