import * as React from "react"
import { Link } from "gatsby"

import Layout from "./layout"
import Seo from "./seo"
import * as styles from "./index.module.css"
import "../pages/index.css"
import data from '../data/data.json'
import $ from "jquery";
import ReactPaginate from 'react-paginate';
import search from '../images/search.svg'
import favs from '../images/heart.svg'
import share from '../images/share.svg'
import ReactTooltip from 'react-tooltip';
import { navigate } from 'gatsby'

import ProductItem from "../components/productItem"
//import numberOfWords from "word-frequency-basic"

function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  console.log(pairs)
  for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

class IndexPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { offset: 0, pageNr: 0, order: "POPULARITY", category: "alle", itemsPerPage: 16, allItems: data.items, favs: [],
      items: data.items.sort((a, b) => (parseInt(b["reviews-count"]) * parseInt(b["rating"])) - (parseInt(a["reviews-count"]) * parseInt(a["rating"])))
    };

    this.handleOnSearch = this.handleOnSearch.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.filterItems = this.filterItems.bind(this)
    this.sortItems = this.sortItems.bind(this)
    this.encode = this.encode.bind(this)
    this.decode = this.decode.bind(this)
    this.fallbackCopyTextToClipboard = this.fallbackCopyTextToClipboard.bind(this)
    this.updateFavs = this.updateFavs.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }
  componentDidMount() {
    let query = parseQuery(this.props.location.search)
    if (query.favs) {
      this.decode(query.favs)
    }
    console.log(query)
    if (query.search) {
      this.filterItems("search", query.search)
    }
    
    /*
    var text = ""
    for (let i = 0; i < data.items.length; i++) {
        const element = data.items[i];
        text += " " + element.title
    }
    let sortable = [];
    let freq = numberOfWords(text)
    for (var word in freq) {
        sortable.push({word: word, val: freq[word]});
    }
    sortable.sort(function(a, b) {
      return b.val - a.val;
    });
    */
  
    
    this.setState({pageCount: Math.ceil(data.items.length / this.state.itemsPerPage), favs: JSON.parse(localStorage.getItem("favs")) || []})
  }

  componentWillUnmount() {
  }
  handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
  }
  handlePageClick = (event) => {
    const newOffset = (event.selected * this.state.itemsPerPage) % data.items.length;
    this.setState({offset: newOffset, pageNr: event.selected});
  };
  encode = () => {
    let str = JSON.stringify(localStorage.getItem("favs"))
    let result = window.btoa(unescape(encodeURIComponent( str )));
    return result
  }
  decode = (b64) => {
    try {
      let oldFavs = JSON.parse(localStorage.getItem("favs")) || []
      let favsArr = JSON.parse(decodeURIComponent(escape(window.atob( b64 ))).replace(/\"/g, "")) || []
      for (var i = 0; i < favsArr.length; i++) {
        if (oldFavs.indexOf(favsArr[i]) === -1){
          oldFavs.push(favsArr[i])
        }
      }
      localStorage.setItem("favs", JSON.stringify(oldFavs))
      this.filterItems("favs")
    } catch (error) {
      
    }
    
  }
  filterItems = (filterType , filterText) => {
    let items = []
    if (filterType === "category") {
      if (filterText == "alle") {
        items = this.state.allItems
      } else {
        items = this.state.allItems.filter((item) => {
          return item.categories.indexOf(filterText) !== -1
        })
      }
      this.setState({category: filterText})
    }
    if (filterType === "favs") {
      let favs = JSON.parse(localStorage.getItem("favs")) || []
      items = this.state.allItems.filter((item) => {
        return favs.indexOf(item.articleNum) !== -1
      })
      this.setState({category: "alle", favs: favs})
    }
    if (filterType === "search") {
      console.log(filterText)
      items = this.state.allItems.filter((item) => {
        return item.title.toLowerCase().includes(filterText.toLowerCase())
      })
      this.setState({category: "alle"})
    }
    this.setState({offset: 0, pageNr: 0})
    this.sortItems(this.state.order, items)
  }
  sortItems = (order, currentitems) => {
    let items = currentitems || this.state.items
    console.log(items)
    let sortedItems = [];
    if (items.length > 0) {
      if (order === "ASC") {
        sortedItems = items.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
      } else if (order === "DESC") {
        sortedItems = items.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
      } else {
        sortedItems = items.sort((a, b) => (parseInt(b["numRating"]) * parseFloat(b["avgRating"].replace(",", "."))) - (parseInt(a["numRating"]) * parseFloat(a["avgRating"].replace(",", "."))))
      }
    }
   
    this.setState({items: sortedItems, order: order})
  }
  
  updateFavs(articleNum) {
    let favsArr = this.state.favs
    if (favsArr.indexOf(articleNum) === -1) {
        favsArr.push(articleNum)
    } else {
        favsArr.splice(favsArr.indexOf(articleNum), 1)
    }

    this.setState({favsArr})
    localStorage.setItem("favs", JSON.stringify(favsArr))
  }
  fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }
  onChange = (e) => {
    this.setState({searchStr: e.target.value})
  }
  onSubmit = (e) => {
    e.preventDefault()
    this.filterItems("search", this.state.searchStr)
  }
  componentDidUpdate(prevProps) {
    console.log(prevProps.location)
    if (this.props.location.search === "" && prevProps.location.search !== "") {
      this.setState({searchStr: ""}, () => {
        this.filterItems("search", this.state.searchStr)
      })
    }
  }
  render() {
    //<img style={{ margin: "auto", width: "85%", marginLeft: 0}} src={mapCaribbean} />
    let items = this.state.items.map((item, key) => {
      return <ProductItem item={item} key={key} favs={this.state.favs} updateFavs={this.updateFavs} />
    })

    let itemSchemas = []
    for (let i = 0; i < this.state.items.length; i++) {
      let item = this.state.items[i];
      // Titel
      let title = ""
      let titleArray = item.title.split(/,|\-|–/)

      for (let k = 0; k < titleArray.length; k++) {
          const titleElement = titleArray[k];
          if (title.length < 22) {
              title += titleElement
          }
      }
      title = title.replace(/\s+/g, ' ');

      // Image
      let imgObj = {
        img: "",
        width: 0
      }
      let arr = []
      if (item.img) {
        arr = JSON.parse(item.img)
      }
      for (let img in arr) {
        if (arr[img][0] > imgObj.width) {
          imgObj.img = img
          imgObj.width = arr[img][0]
  
        }
      }
      // Description
      let description = item.desc || ""
      
      description = description.replace(/"/g, "\\\"")
      title = title.replace(/"/g, "\\\"")
      
      let avgRating = item.avgRating
      if (avgRating === "N/A") {
        avgRating = 0
      }
      
      if (item.price) {
        itemSchemas.push(`
        {
          "@type": "Product",
          "name": "${title}",
          "image": "${imgObj.img}",
          "description": "${description}",
          "brand": {
            "@type": "Brand",
            "name": "Matchbox"
          },
          "audience": {
              "@type": "PeopleAudience",
              "suggestedGender": "unisex",
              "suggestedMinAge": 5
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "${avgRating}",
            "reviewCount": "${item.numRating}"
          },
          "keywords": "Matchbox, ${item.categories}",
          "offers": {
            "@type": "Offer",
            "url": "${"https://www.matchbox.autos/produkt/" + title.replace(/ /g, "-").toLowerCase() + "/"}",
            "price": "${item.price.replace("€", "").replace(",", ".")}",
            "priceCurrency": "EUR"
          }
        }`)
      }
    }

    const endOffset = this.state.offset + this.state.itemsPerPage;
    let currentItems = items.slice(this.state.offset, endOffset)
    let pagecount = Math.ceil(items.length / this.state.itemsPerPage)
    let categoriesByType = []

    return (
    <div>
       <Seo title="Matchbox Autos" description={"Durchsuchen sie einfach unseren Katalog von Matchbox Autos. Sie können ganz bequem über Amazon bestellen."}>
          <script type='application/ld+json'>
            {
              `
              {
                "@context": "http://schema.org",
                "@type": "ItemList",
                "url": "http://www.matchbox.autos/",
                "numberOfItems": "${items.length}",
                "itemListElement": [${itemSchemas}]
              }
              `
            }
          </script>
        </Seo>
      <Layout onChange={this.onChange} onSubmit={this.onSubmit}>
       
        <div className='products' style={{display: "flex"}}>
          <div className='categories' style={{display: "flex", flexDirection: "column", minWidth: 225, marginRight: 30, paddingRight: 10, paddingTop: 35, background: "#fff", borderRight: "1px solid var(--light-grey)"}}>

            <div className='favs' style={{pointer: "cursor", margin: "25px auto", marginTop: 0, display: "flex", flexDirection: "column"}}>
              <div className='row' style={{cursor: "pointer"}}>
                <div style={{padding: 9, width: 40}}>
                  <img style={{display: "flex", margin: "auto"}} src={favs} alt="favorite-icon" />
                </div>
                <div className='' style={{pointer: "cursor", padding: "5px 15px", display: "flex", justifyContent: "center", flexDirection: "column"}} onClick={() => this.filterItems("favs")}>
                  Favoriten Anzeigen
                </div>
              </div>
              <div className='row' style={{cursor: "pointer", marginBottom: "-1px", pointer: "cursor",}}>
                <div style={{padding: 9,  width: 40}}>
                  <img style={{display: "flex", margin: "auto"}} src={share} alt="share-icon"/>
                </div>
                <div ref={ref => this.fooRef = ref} data-tip="Link wurde in die Zwischenablage kopiert!" className='' style={{padding: "5px 15px", display: "flex", justifyContent: "center", flexDirection: "column" }} onClick={() => {
                  let code = this.encode()
                  if (!navigator.clipboard) { 
                    this.fallbackCopyTextToClipboard("https://www.hotwheels.autos?favs=" + code);
                    return;
                  }
                  navigator.clipboard.writeText("https://www.hotwheels.autos?favs=" + code).then(function() {
                    console.log('Async: Copying to clipboard was successful!');
                  }, function(err) {
                    console.error('Async: Could not copy text: ', err);
                  });
                  ReactTooltip.show(this.fooRef)
                }}>
                  Favoriten Teilen
                </div>
              </div>
            </div>
            <ReactTooltip place="bottom" type="dark" effect="solid" event="click" afterShow={() => {
              setTimeout(() => {
                ReactTooltip.hide()
              }, 2500)
            }}/>
            <div className={"button " + (this.state.category == "alle" ? "selected" : "")} onClick={(e) => {
              this.filterItems("category", "alle")
            }} style={{display: "flex", width: "100%", justifyContent: "space-between", fontWeight: 400, cursor: "pointer", padding: "10px 5px",paddingRight: 10, margin: "15px 0px"}}>
              <div className= "category" style={{marginLeft: 5}}>{"Alles anzeigen"}</div><div style={{marginLeft: "auto"}}>{data.items.length}</div>
            </div>
            {data.types.map((type, k) => {
              return (
                <div key={k} style={{color: "var(--color-text-lighter)"}}>
                  <div className='' style={{color: "var(--color-text)", padding: "5px 10px", fontWeight: "bold", marginTop: 10, marginBottom: 5}}>
                     {type.name}
                  </div>
                  {type.categories.sort((a, b) => {
                    if (type.name == "Fahrzeug Packs" || type.name == "Maßstab") {
                      return a.name.localeCompare(b.name, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                      });
                    } else {
                      return b.val - a.val
                    }
                    
                  }).map((category, i) => {
                    return <div className={"category-item button " + (this.state.category == category.name ? "selected" : "")} key={i} onClick={(e) => {
                        let text = "" 

                        if ($(e.target).hasClass("category-item")) {
                          text = $(e.target).children(".category").text()
                        } else {
                          text = $(e.target).parent(".category-item").children(".category").text()
                        }
                        this.filterItems("category", text)
                      }} style={{display: "flex", width: "100%", justifyContent: "", cursor: "pointer", padding: "5px 5px", paddingRight: 10, margin: "5px 0px"}}>
                      <div className="marker"></div><div className="category" style={{marginLeft: 5, lineHeight: "30px"}}>{category.name}</div><div style={{marginLeft: "auto", lineHeight: "30px"}}>{category.val}</div>
                    </div>
                  })}
                </div>
              )
            })}
            
          </div>
          <div className="wrapper-product-wrapper" style={{width: "80%", margin: "0px auto", paddingTop: 35}}>
            <div className='order-buttons' style={{display: "flex", marginBottom: 15, justifyContent: "space-between" }}>
                <div className='search' style={{border: "1px solid #000", display: "flex"}}>
                  <div style={{padding: 9, borderRight: "1px solid #000", width: 40}}>
                    <img style={{display: "flex", margin: "auto"}} src={search} />
                  </div>
                  <form style={{display: "flex"}} onSubmit={(e) => {
                      e.preventDefault()
                      navigate('/?search=' + this.state.searchStr, {replace: true});
                      this.filterItems("search", this.state.searchStr)
                    }}>
                    <input placeholder="Artikel suchen" style={{border: "none", outline: "none", marginLeft: 5, minWidth: 250}} value={this.state.searchStr} onChange={(e) => {
                      this.setState({searchStr: e.target.value})
                    }}/>
                  </form>
                  
                </div>
                <div className='row' style={{lineHeight: "31px"}}>
                  <div className='' style={{marginRight: 15, lineHeight: "41px", fontWeight: 400}}>
                    Sortierung: 
                  </div>
                <div className={"button " + (this.state.order == "POPULARITY" ? "selected" : "")} style={{cursor: "pointer", marginRight: 15}} onClick={() => this.sortItems("POPULARITY")}>
                  Beliebtheit
                </div>
                <div className={"button " + (this.state.order == "DESC" ? "selected" : "")} style={{cursor: "pointer"}} onClick={() => this.sortItems("DESC")}>
                  Preis absteigend
                </div>
                <div className={"button " + (this.state.order == "ASC" ? "selected" : "")} style={{cursor: "pointer"}} onClick={() => this.sortItems("ASC")}>
                  Preis aufsteigend
                </div>
                   
                </div>
                
            </div>
            <div className="product-grid" style={{marginTop: 15}}>
              {currentItems}
            </div>
            <div className="pagination">
            <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={this.handlePageClick}
                forcePage={this.state.pageNr}
                pageRangeDisplayed={2}
                pageCount={pagecount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />
          </div>
          </div>
          
        </div>
      </Layout> 
    </div>)
  }
}


export default IndexPage
