import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const FavIcon = () => (
  <>
    <svg  viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.2231 2.12485C19.0711 0.972852 17.546 0.343216 15.9183 0.343216C14.2906 0.343216 12.7608 0.977516 11.6088 2.12952L11.0071 2.73117L10.3961 2.12019C9.24414 0.968188 7.70969 0.329224 6.08197 0.329224C4.4589 0.329224 2.92912 0.963524 1.78178 2.11086C0.629784 3.26286 -0.00451636 4.79264 0.000147609 6.42037C0.000147609 8.0481 0.639112 9.57322 1.79111 10.7252L10.5501 19.4842C10.6713 19.6054 10.8346 19.6707 10.9931 19.6707C11.1517 19.6707 11.3149 19.6101 11.4362 19.4888L20.2138 10.7439C21.3658 9.59187 22.0001 8.06209 22.0001 6.43436C22.0048 4.80664 21.3751 3.27685 20.2231 2.12485ZM19.3276 9.85306L10.9931 18.1549L2.67727 9.83906C1.76313 8.92492 1.25942 7.71229 1.25942 6.42037C1.25942 5.12845 1.75846 3.91582 2.6726 3.00634C3.58208 2.09687 4.79471 1.59316 6.08197 1.59316C7.37389 1.59316 8.59118 2.09687 9.50532 3.01101L10.5594 4.06506C10.8066 4.31226 11.203 4.31226 11.4502 4.06506L12.4949 3.02034C13.4091 2.1062 14.6264 1.60249 15.9136 1.60249C17.2009 1.60249 18.4135 2.1062 19.3276 3.01567C20.2418 3.92981 20.7408 5.14244 20.7408 6.43436C20.7455 7.72628 20.2418 8.93892 19.3276 9.85306Z" fill="#FA0000"/>
<path id="background" d="M19.3276 9.85306L10.9931 18.1549L2.67727 9.83906C1.76313 8.92492 1.25942 7.71229 1.25942 6.42037C1.25942 5.12845 1.75846 3.91582 2.6726 3.00634C3.58208 2.09687 4.79471 1.59316 6.08197 1.59316C7.37389 1.59316 8.59118 2.09687 9.50532 3.01101L10.5594 4.06506C10.8066 4.31226 11.203 4.31226 11.4502 4.06506L12.4949 3.02034C13.4091 2.1062 14.6264 1.60249 15.9136 1.60249C17.2009 1.60249 18.4135 2.1062 19.3276 3.01567C20.2418 3.92981 20.7408 5.14244 20.7408 6.43436C20.7455 7.72628 20.2418 8.93892 19.3276 9.85306Z" fill="white"/>
</svg>


  </>
)

export default FavIcon
